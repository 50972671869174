<template>
  <v-app-bar
    v-scroll="onScroll"
    :color="!isScrolling ? 'transparent' : 'white'"
    fixed
    flat
  >
    <v-slide-x-transition v-if="$vuetify.breakpoint.mdAndUp">
        <div
            class="text-subtitle-1 text--primary font-weight-medium"
            v-if="showLogo"
        >
          Gerhard Herrera
        </div>
    </v-slide-x-transition>
    <v-spacer/>
    <social-media appbar />
    <v-speed-dial
        v-model="fab"
        direction="bottom"
        transition="scale-transition"
    >
      <template v-slot:activator>
        <v-btn
            color="info"
            elevation="0"
            fab
            dark
            small
            class="ma-1"
            v-model="fab"
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <span
              v-else
          >
            {{$t(`lng.${$i18n.locale}`)}}
          </span>
        </v-btn>
      </template>
      <v-btn
          color="info"
          fab
          small
          class="text-subtitle-1 font-weight-bold"
          @click="changeLocale('de')"
      >
        {{$t('lng.de')}}
      </v-btn>
      <v-btn
          fab
          small
          color="info"
          class="text-subtitle-1 font-weight-bold"
          @click="changeLocale('en')"
      >
        {{$t('lng.en')}}
      </v-btn>
      <v-btn
          fab
          small
          color="info"
          class="text-subtitle-1 font-weight-bold"
          @click="changeLocale('es')"
      >
        {{$t('lng.es')}}
      </v-btn>
    </v-speed-dial>
  </v-app-bar>
</template>

<script>
  import i18n from '@//plugins/i18n'

  export default {
    name: 'CoreAppBar',

    components: {
      SocialMedia: () => import('@/components/SocialMedia'),
    },

    data: () => ({
      showLogo: false,
      isScrolling: false,
      fab: false
    }),

    methods: {
      onScroll () {
        const offset = window.pageYOffset
        this.isScrolling = offset > 50
        this.showLogo = offset > 200
      },
      changeLocale(locale){
        i18n.locale = locale
        this.$eventHub.$emit('locale-changed')
      }
    },
  }
</script>
